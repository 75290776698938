
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/data/badge/BasicUsage.vue";
import EUIMaxValue from "@/views/resources/documentation/element-ui/data/badge/MaxValue.vue";
import EUICustomizations from "@/views/resources/documentation/element-ui/data/badge/Customizations.vue";
import EUILittleRedDot from "@/views/resources/documentation/element-ui/data/badge/LittleRedDot.vue";

export default defineComponent({
  name: "badge",
  components: {
    EUIBasicUsage,
    EUIMaxValue,
    EUICustomizations,
    EUILittleRedDot
  },
  setup() {
    setCurrentPageTitle("Badge");
  }
});
